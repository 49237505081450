import React from "react"

import PageWrapper from "../../components/PageWrapper"
import ExternalLink from "../../components/basic/ExternalLink"
import ContactSection from "../../components/content/ContactSection"
import ProductOverviewSection from "../../components/content/ProductOverviewSection"
import { faDog, faUser } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const FuerHundePage = () => {
  return (
    <PageWrapper title="Produkte für den Menschen" >
      <section id="produkte" className="mt-5">
        <h2>
          <FontAwesomeIcon icon={faDog} className="mr-3 ml-1"/>
          Für Hunde
       </h2>
        <h5 className="mb-4" style={{color: "rgba(0,0,0,0.7)"}}>
          Fast so gut wie ein frischer Hase!
        </h5>
        <div style={{maxWidth: 800}}>
          <p className="mb-2">
            Hier finden Sie alles für das Wohlbefinden ihres Vierbeiners ! Die Naturprodukte von Reico orientieren sich am natürlichen Beutetier des Hundes und sorgen somit für eine optimale Ernährung, ganz ohne Jagd...
          </p>

          <p className="mb-4" style={{fontWeight: "700"}}>
            Bringen auch Sie Ihren Hund ins mineralische Gleichgewicht!

          </p>
          <p>
            Wenn Sie sich für eines der Produkte interessieren,
            können Sie die Produkte direkt bei <ExternalLink href="#contact" text="mir" /> bestellen, sich kostenlos beraten lassen und als Kunde erhalten
            Sie dann auch ihre eigenen Zugangsdaten um jederzeit selbst Bestellungen zu tätigen.
          </p>
        </div>
        <ProductOverviewSection target="Dog" />
      </section>
      <hr className="my-5"/>
      <ContactSection />
    </PageWrapper>
  )
}

export default FuerHundePage
